import {APP_STORAGE_KEY} from '../../config/constants/app';

export const localStorageMiddleware =
    ({getState}: { getState: any }) =>
        (next: any) =>
            (action: any) => {
                const result = next(action);
                localStorage.setItem(APP_STORAGE_KEY, JSON.stringify(getState()));
                return result;
            };

export const reHydrateStore = () => {
    if (localStorage.getItem(APP_STORAGE_KEY)) {
        return JSON.parse(localStorage.getItem(APP_STORAGE_KEY) as string);
    }

    return {};
};
