import React from 'react';

import Button from '../../../Button';
import DataListItem from "../../../DataListItem";
import {CartItemType} from "../../../../config/types/cart";
import {formatCartItemQuantity} from "../../../../utilities/cart/formatCartItemQuantity";

type CartListItemType = {
    cartItem: CartItemType;
    removeItemFromCartHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const CartListItem: React.FC<CartListItemType> = ({cartItem, removeItemFromCartHandler}) => (
    <DataListItem>
        <div className="header">
            <div className="col-9 title">
                <div>
                    <i className="custom-icon icon" aria-label="Tag"/>
                </div>
                <div>
                    <h2>
                        {cartItem.itemName}
                        <small>{formatCartItemQuantity(cartItem)}</small>
                    </h2>
                </div>
            </div>
            <div className="col-3 action-col text-right">
                <Button
                    data-supply={cartItem.itemId}
                    onClick={removeItemFromCartHandler}
                    className="btn-link delete-btn no-text"
                >
                    <i className="custom-icon icon" aria-label="Trash"/>
                </Button>
            </div>
        </div>
    </DataListItem>
);

export default CartListItem;

