import React from 'react';
import {Outlet} from 'react-router-dom';

const AuthenticationPageLayout: React.FC = () => (
    <div className="container-fluid">
        <div className="auth-wrapper">
            <Outlet/>
        </div>
    </div>
);

export default AuthenticationPageLayout;
