import { useEffect, useState } from 'react';

import useTeamList from './useTeamList';
import useTeamMemberList from './useTeamMemberList';

import { AuthorisedMembers } from '../../config/types/authentication';
import useAuthenticatedUser from '../useAuthenticatedUser';

let loadOnce = true;

const useAuthorisedTeamMembers = (authorisedToLogin = true) => {
    const { fetchTeams, teamList } = useTeamList();
    const { fetchTeamMembers, teamMemberList } = useTeamMemberList();
    const { authenticatedUser } = useAuthenticatedUser();
    const [authorisedMembers, setAuthorisedMembers] = useState<AuthorisedMembers[]>([]);

    const defaultMemberPrivileges = {
        accountAdmin: false,
        deleteRecord: false,
        delivery: false,
        supplies: false,
        team: false,
        siteInspection: false,
        sites: false,
        orders: false,
        shop: false,
        vehicles: false,
        settings: false,
    };

    useEffect(() => {
        fetchTeams();
        fetchTeamMembers();
    }, [authorisedToLogin, fetchTeams, fetchTeamMembers]);

    useEffect(() => {
        if (
            authenticatedUser.authenticationToken !== '' &&
            teamList.length > 0 &&
            teamMemberList.length > 0 &&
            loadOnce
        ) {
            loadOnce = false;
            const updatedAuthorisedMembers = teamMemberList.map((teamMember) => ({
                authenticationToken: '',
                accountNo: authenticatedUser.accountNo,
                memberId: teamMember.memberId,
                firstName: teamMember.firstName,
                lastName: teamMember.lastName,
                tel: teamMember.tel,
                privileges:
                    teamList.find((team) => team.teamId === teamMember.teamId)?.privileges || defaultMemberPrivileges,
            }));

            setAuthorisedMembers(updatedAuthorisedMembers);
        }
    }, [teamList, teamMemberList, authenticatedUser, defaultMemberPrivileges]);

    return { authorisedMembers };
};

export default useAuthorisedTeamMembers;
