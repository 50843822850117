import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticationType } from '../../config/types/authentication';

const authentication = createSlice({
    name: 'Authentication',
    initialState: {} as AuthenticationType,
    reducers: {
        login: (state: AuthenticationType, action: PayloadAction<AuthenticationType>) => ({
            ...action.payload,
        }),
        logout: (state: AuthenticationType) => ({ ...state, authenticationToken: '' }),
    },
});

export const { login, logout } = authentication.actions;
export default authentication.reducer;
