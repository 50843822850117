import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Toaster } from 'react-hot-toast';

import { useAppDispatch } from './hooks/redux';
import { initialiseCart } from './redux/reducers/cart';
/*
import { teams } from './data/team/team';
import { teamMembers } from './data/team/teamMember';
import { loadDemoTeam, loadDemoTeamMembers } from './redux/reducers/team';
*/

import { APP_STORAGE_KEY } from './config/constants/app';

import PrimaryRouter from './routes/PrimaryRouter';

let loadAppReduxStoreItemsOnce = true;

const App = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!loadAppReduxStoreItemsOnce) return;
        loadAppReduxStoreItemsOnce = false;
        if (dispatch && !localStorage.getItem(APP_STORAGE_KEY)) {
            dispatch(initialiseCart);
            /*
            dispatch(loadDemoTeam(teams));
            dispatch(loadDemoTeamMembers(teamMembers));
            */
        }
    }, [dispatch]);

    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <PrimaryRouter />
        </>
    );
};

export default connect()(App);
