import { useCallback, useState } from 'react';

import useHttp from '../useHttp';
import { SiteListType } from '../../config/types/sites/site';

const useSites = () => {
    const { sendHttpRequest, httpInProgress: fetchingSites, httpError: siteHttpError } = useHttp();
    const [sites, setSites] = useState<SiteListType[]>([]);

    const fetchSites = useCallback((filterParams?: Record<string, any>) => {
        if (fetchingSites) {
            return;
        }

        const httpRequestConfig = {
            url: '/site',
            params: filterParams,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        sendHttpRequest(httpRequestConfig, (updatedSites) => {
            if ('records' in updatedSites) {
                setSites(updatedSites.records as SiteListType[]);
            }
        });
    }, []);

    return { fetchSites, fetchingSites, siteHttpError, sites };
};

export default useSites;
