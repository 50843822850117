import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartType, CartItemType } from '../../config/types/cart';

const cart = createSlice({
    name: 'cartItems',
    initialState: { siteId: '', driverId: '', cartItems: [] } as CartType,
    reducers: {
        initialiseCart: () => ({ siteId: '', driverId: '', cartItems: [] }),
        updateCart: (state, action: PayloadAction<CartType>) => ({ ...state, ...action.payload }),
        addItemToCart: (state, action: PayloadAction<CartItemType>) => {
            const { itemId, itemQuantity } = action.payload;

            let updatedCartItems = (state.cartItems as CartItemType[]).slice();
            const cartItemIndex = updatedCartItems.findIndex((cartItem) => cartItem.itemId === itemId);
            if (cartItemIndex > -1) {
                updatedCartItems[cartItemIndex] = {
                    ...updatedCartItems[cartItemIndex],
                    itemQuantity: updatedCartItems[cartItemIndex].itemQuantity + itemQuantity,
                };
            } else {
                updatedCartItems = [...updatedCartItems, { ...action.payload }];
            }

            return { ...state, cartItems: [...updatedCartItems] };
        },

        updateCartItemQuantity: (state, action: PayloadAction<CartItemType>) => {
            const { itemId, itemQuantity } = action.payload;
            const updatedCartItems = (state.cartItems as CartItemType[]).slice();
            const cartItemIndex = updatedCartItems.findIndex((cartItem) => cartItem.itemId === itemId);
            if (cartItemIndex > -1) {
                updatedCartItems[cartItemIndex] = {
                    ...updatedCartItems[cartItemIndex],
                    itemQuantity,
                };

                return { ...state, cartItems: [...updatedCartItems] };
            }

            return state;
        },

        removeItemFromCart: (state, action: PayloadAction<string>) => {
            const itemId = action.payload.trim();
            const updatedCartItems = (state.cartItems as CartItemType[]).slice();
            const cartItemIndex = updatedCartItems.findIndex((cartItem) => cartItem.itemId === itemId);
            if (cartItemIndex > -1) {
                return {
                    ...state,
                    cartItems: [...updatedCartItems.filter((cartItem) => cartItem.itemId !== itemId)],
                };
            }

            return state;
        },

        clearCart: () => ({ siteId: '', driverId: '', cartItems: [] }),
    },
});

export const { initialiseCart, updateCart, addItemToCart, updateCartItemQuantity, removeItemFromCart, clearCart } =
    cart.actions;
export default cart.reducer;
