import React from 'react';

type ComponentProgressIndicatorTypes = {
    children: React.ReactNode;
    removePadding?: boolean;
};

const ComponentProgressIndicator: React.FC<ComponentProgressIndicatorTypes> = ({ children, removePadding }) => {
    const padding = typeof removePadding !== 'undefined' && removePadding ? 0 : 50;

    return (
        <div className="component-loading animated fadeIn">
            <div style={{ paddingTop: padding }} className="loading">
                <span />
                <span />
                <span />
                <span />
            </div>
            <div style={{ paddingTop: padding }} className="loading-text small-text">
                {children}
            </div>
        </div>
    );
};

ComponentProgressIndicator.defaultProps = {
    removePadding: false,
};
export default ComponentProgressIndicator;
