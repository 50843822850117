import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TaxType } from '../../../config/types/tax';

const taxes = createSlice({
    name: 'Taxes',
    initialState: [] as TaxType[],
    reducers: {
        setTaxes: (state, action: PayloadAction<TaxType[]>) => [...action.payload],
    },
});

export const { setTaxes } = taxes.actions;
export default taxes.reducer;
