import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import { localStorageMiddleware, reHydrateStore } from './utilities/localStorage';

import { taxReducer, taxOptionReducer } from './reducers/tax';
import cartReducer from './reducers/cart';
import authenticationReducer from './reducers/authentication';

const store = configureStore({
    reducer: {
        cart: cartReducer,
        taxes: taxReducer,
        taxOptions: taxOptionReducer,
        authenticatedUser: authenticationReducer,
    },
    preloadedState: reHydrateStore(),
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(localStorageMiddleware).concat(logger),
});

store.getState();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
