import React from 'react';

/* eslint-disable import/no-useless-path-segments */
import Button from '../../../Button';
import DataListItem, { toggleDataListItem } from '../../../DataListItem';
import { formatNumber } from '../../../../utilities/strings';
import { SupplyCacheInventoryListType } from '../../../../config/types/supplies/supplyCacheInventory';
/* eslint-disable import/no-useless-path-segments */

type TeamMemberWalletItemListType = {
    walletItems: SupplyCacheInventoryListType[];
    walletItemsAddedToCart: boolean;
    addWalletItemsToCartHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    removeWalletItemsFromCartHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const TeamMemberWalletItemList: React.FC<TeamMemberWalletItemListType> = ({
    walletItems,
    walletItemsAddedToCart,
    addWalletItemsToCartHandler,
    removeWalletItemsFromCartHandler,
}) => (
    <DataListItem>
        <div className="header">
            <div className="col-8 title">
                <div>
                    <i className="custom-icon icon" aria-label="Tag" />
                </div>
                <div>
                    <h2>
                        {walletItems.length} Similar Item(s)
                        <small>Found in Selected Driver&apos;s Supply Wallet</small>
                    </h2>
                </div>
            </div>
            <div className="col-md-2 action-col text-right">
                {walletItemsAddedToCart ? (
                    <Button onClick={removeWalletItemsFromCartHandler} className="btn-link delete-btn">
                        Remove Wallet Item(s)
                    </Button>
                ) : (
                    <Button onClick={addWalletItemsToCartHandler} className="btn-link upload-btn">
                        Use Wallet Item(s)
                    </Button>
                )}
                <Button className={`btn btn-link no-text`} onClick={toggleDataListItem}>
                    <i className="custom-icon icon" aria-label="Chevron-Down" />
                </Button>
            </div>
        </div>
        <div className="body">
            <table className="table table-borderless table-hover">
                <thead>
                    <tr>
                        <th className="text-right col-md-2">Qty</th>
                        <th className="text-right col-md-1">Unit</th>
                        <th className="text-left col-md-9">Description</th>
                    </tr>
                </thead>
                <tbody>
                    {walletItems.map((walletItem) => (
                        <tr key={walletItem.cacheItemId}>
                            <td className="text-right">
                                {formatNumber(walletItem.itemQuantity / walletItem.itemMinimumSaleQuantity, 3)}
                            </td>
                            <td className="text-right">{walletItem.itemSIUnit}</td>
                            <td className="text-right">{walletItem.itemName}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </DataListItem>
);

export default TeamMemberWalletItemList;
