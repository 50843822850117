import React from 'react';

export const toggleDataListItem = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const datalistContainer = event.currentTarget?.parentElement?.offsetParent as HTMLDivElement;
    if (datalistContainer && event.currentTarget?.classList.contains('active')) {
        datalistContainer.classList.remove('active');
        event.currentTarget?.classList.remove('active');
        let preferredIcon = event.currentTarget?.dataset.icon;
        if (typeof preferredIcon === 'undefined') {
            preferredIcon = 'Chevron-Down';
        }
        event.currentTarget?.children[0]?.setAttribute('aria-label', preferredIcon);
        return;
    }
    /* document.querySelectorAll('.data-list-item-wrapper').forEach((datalistWrapper) => {
        if (datalistWrapper && datalistWrapper.classList.contains('active')) {
            datalistWrapper.classList.remove('active');
        }
    });

    document.querySelectorAll('.icon[aria-label*="Close"]').forEach((icon) => {
        icon.setAttribute('aria-label', 'Chevron-Down');
    }); */

    datalistContainer?.classList.add('active');
    event.currentTarget?.classList.add('active');
    event.currentTarget?.children[0]?.setAttribute('aria-label', 'Close');
};
