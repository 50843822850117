import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SelectSingleOptionType } from '../../../components/FormElements';
import { TaxType } from '../../../config/types/tax';

const taxOptions = createSlice({
    name: 'Tax Options',
    initialState: [] as SelectSingleOptionType[],
    reducers: {
        setTaxOptions: (state, action: PayloadAction<TaxType[]>) =>
            [...action.payload].map((tax) => ({
                value: tax.taxId,
                label: tax.taxName,
            })),
    },
});

export const { setTaxOptions } = taxOptions.actions;
export default taxOptions.reducer;
