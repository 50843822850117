import React from 'react';

import Button from '../../../Button';
import {Card} from '../../../Card';

type EmptyCartType = {
    cartModalExitHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const EmptyCart: React.FC<EmptyCartType> = ({cartModalExitHandler}) => (
    <div className="row p-5">
        <div className="col-md-6 offset-md-3">
            <Card className="p-5 bg-transparent border-0">
                <div className="d-flex flex-column align-items-center justify-content-center flex-fill">
                    <i
                        className="custom-icon icon"
                        aria-label="Shopping Cart"
                        style={{width: 48, height: 48}}
                    />
                    <p className="hint-text text-center">No item available in your cart.</p>
                    <Button
                        type="button"
                        onClick={cartModalExitHandler}
                        className="btn-outline-success btn-block btn-rounded"
                    >
                        <div className="d-flex flex-row align-items-center justify-content-center">
                            <i className="custom-icon icon mr-auto pr-2" aria-label="Shopping Cart"/>
                            <span className="mr-auto">Continue Shopping</span>
                        </div>
                    </Button>
                </div>
            </Card>
        </div>
    </div>
);

export default EmptyCart;
