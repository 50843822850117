import { useCallback, useState } from 'react';

import useHttp from '../useHttp';
import { SiteGroupListType } from '../../config/types/sites/siteGroup';

const useSiteGroups = () => {
    const { sendHttpRequest, httpInProgress: fetchingSiteGroups, httpError: siteGroupsError } = useHttp();
    const [siteGroups, setSiteGroups] = useState<SiteGroupListType[]>([]);

    const fetchSiteGroups = useCallback((filterParams?: Record<string, any>) => {
        if (fetchingSiteGroups) {
            return;
        }

        const httpRequestConfig = {
            url: '/site/group',
            params: filterParams,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        sendHttpRequest(httpRequestConfig, (updatedSiteGroups) => {
            if ('records' in updatedSiteGroups) {
                setSiteGroups(updatedSiteGroups.records as SiteGroupListType[]);
            }
        });
    }, []);

    return { fetchSiteGroups, fetchingSiteGroups, siteGroupsError, siteGroups };
};

export default useSiteGroups;
