import {useCallback, useState} from 'react';

import useHttp from '../useHttp';
import {TeamListType} from '../../config/types/team/team';

const useTeamList = () => {
    const {sendHttpRequest, httpInProgress: fetchingTeams, httpError: teamHttpError} = useHttp();
    const [teamList, setTeamList] = useState<TeamListType[]>([]);

    const fetchTeams = useCallback((filterParams?: Record<string, any>) => {
        if (fetchingTeams) {
            return;
        }

        const httpRequestConfig = {
            url: '/team',
            params: filterParams,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        sendHttpRequest(httpRequestConfig, (updatedTeams) => {
            if ('records' in updatedTeams) {
                setTeamList(updatedTeams.records as TeamListType[]);
            }
        });
    }, []);

    return {fetchTeams, fetchingTeams, teamHttpError, teamList};
};

export default useTeamList;
