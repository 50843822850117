import React, { useState, useEffect } from 'react';
import { Divide as Hamburger } from 'hamburger-react';
import { Link, useNavigate } from 'react-router-dom';
import { Method } from 'axios';

import useHttp from '../../../../hooks/useHttp';
import { useAppDispatch } from '../../../../hooks/redux';
import { useToastNotification } from '../../../../hooks/notification';
import useShoppingCart from '../../../../hooks/shopping/useShoppingCart';
import { updateCart, removeItemFromCart, clearCart } from '../../../../redux/reducers/cart';
import useAuthorisedTeamMembers from '../../../../hooks/team/useAuthorisedTeamMembers';
import { useSites } from '../../../../hooks/sites';
import { logout } from '../../../../redux/reducers/authentication';

import { UserDropdownMenu } from './elements';
import QuickOrderCart from '../../../../components/Cart/QuickOrderCart';

import { AuthenticationType } from '../../../../config/types/authentication';
import { SupplyCacheInventoryListType } from '../../../../config/types/supplies/supplyCacheInventory';

type AuthenticationPageLayoutHeaderType = {
    openCart: boolean;
    toggleCartHandler: () => void;
    toggleNavigation: () => void;
    navigationBarState: boolean;
    authenticatedUser: AuthenticationType;
    showGlobalAddButton: boolean;
    addSomethingNewHandler: (addSomethingNew: boolean) => void;
};

const AuthenticationPageLayoutHeader: React.FC<AuthenticationPageLayoutHeaderType> = ({
    openCart,
    toggleCartHandler,
    toggleNavigation,
    showGlobalAddButton,
    addSomethingNewHandler,
    navigationBarState,
    authenticatedUser,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toastNotification = useToastNotification();
    const { sendHttpRequest } = useHttp();
    const { privileges: userPrivileges } = authenticatedUser;

    const handleAddSomethingNew = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        addSomethingNewHandler(true);
    };

    const handleUserLogout = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        const httpRequestConfig = {
            url: '/session/logout',
            method: 'POST' as Method,
        };

        sendHttpRequest(httpRequestConfig, (response) => {
            if ('success' in response) {
                dispatch(logout());
                setTimeout(() => {
                    navigate('/', { replace: true });
                }, 500);
            }
        });
    };

    const { shoppingCart } = useShoppingCart();
    const cartItemCount = shoppingCart.cartItems.reduce(
        (total, cartItem) => total + cartItem.itemQuantity / cartItem.itemMinimumSaleQuantity,
        0,
    );

    const handleRemoveItemFromCart = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const { cartItems } = shoppingCart;
        const itemId = event.currentTarget.dataset.supply;
        const itemCount = cartItems.length;
        const selectedCartItem = cartItems.find((cartItem) => cartItem.itemId === itemId);
        if (selectedCartItem) {
            dispatch(removeItemFromCart(selectedCartItem.itemId));
            toastNotification(`${selectedCartItem.itemName} removed from cart!`, 'success');
            if (itemCount > 1) {
                return;
            }
            toggleCartHandler();
        }
    };

    const handleClearCart = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        dispatch(clearCart());
        toastNotification('Cart emptied!', 'success');
        toggleCartHandler();
    };

    const [cachedItems, setCachedItems] = useState<SupplyCacheInventoryListType[]>([]);
    const [cachedItemsAdded, setCachedItemsAdded] = useState(false);
    const [similarCachedItems, setSimilarCachedItems] = useState<SupplyCacheInventoryListType[]>([]);

    useEffect(() => {
        const updatedSimilarCachedItems: SupplyCacheInventoryListType[] = [];
        shoppingCart.cartItems.forEach((cartItem) => {
            const similarCachedItem = cachedItems.filter((cachedItem) => cachedItem.itemId === cartItem.itemId);
            updatedSimilarCachedItems.concat(similarCachedItem);
        });
        setSimilarCachedItems(updatedSimilarCachedItems);
    }, [cachedItems]);

    const handleAddCachedItems = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const updatedCartItems = shoppingCart.cartItems.slice();
        let itemLoopCount = 0;
        shoppingCart.cartItems.slice().forEach((cartItem, index) => {
            const selectedCachedItem = similarCachedItems.find(
                (similarCachedItem) => similarCachedItem.itemId === cartItem.itemId,
            );
            if (selectedCachedItem) {
                if (selectedCachedItem.itemQuantity >= cartItem.itemQuantity) {
                    updatedCartItems[index] = {
                        ...updatedCartItems[index],
                        itemQuantity: 0,
                        itemCacheQuantity: updatedCartItems[index].itemQuantity,
                    };
                } else {
                    const qtyDifference = cartItem.itemQuantity - selectedCachedItem.itemQuantity;
                    updatedCartItems[index] = {
                        ...updatedCartItems[index],
                        itemQuantity: updatedCartItems[index].itemQuantity - qtyDifference,
                        itemCacheQuantity: qtyDifference,
                    };
                }
            }

            itemLoopCount += 1;
            if (itemLoopCount === updatedCartItems.length) {
                dispatch(updateCart({ ...shoppingCart, cartItems: updatedCartItems }));
                toastNotification('Wallet item(s) added to cart!', 'success');
                setCachedItemsAdded(true);
            }
        });
    };

    const handleRemoveCachedItems = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const updatedCartItems = shoppingCart.cartItems.slice().map((cartItem) => ({
            ...cartItem,
            itemCacheQuantity: 0,
            itemQuantity: cartItem.itemCacheQuantity + cartItem.itemQuantity,
        }));
        dispatch(updateCart({ ...shoppingCart, cartItems: updatedCartItems }));
        toastNotification('Wallet item(s) removed from cart!', 'success');
        setCachedItemsAdded(false);
    };

    const handleDriverAndSiteChange = (optionName: string, optionValue: string) => {
        dispatch(updateCart({ ...shoppingCart, [optionName]: optionValue }));
        if (optionName === 'driverId' && userPrivileges.supplies) {
            if (optionValue.trim() === '') {
                setCachedItems([]);
                return;
            }

            const httpRequestConfig = {
                url: 'cache/member',
                params: { userId: optionValue },
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            sendHttpRequest(httpRequestConfig, (updatedCachedItems) => {
                if ('records' in updatedCachedItems) {
                    setCachedItems(updatedCachedItems.records as SupplyCacheInventoryListType[]);
                }
            });
        }
    };

    const handleCheckout = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const { siteId, driverId, cartItems } = shoppingCart;
        const selectedSiteName = siteSelectOptions.find((site) => site.value === siteId)?.label;
        if (siteId.trim() === '' || !selectedSiteName) {
            toastNotification('Invalid delivery site selected!', 'error');
            return;
        }

        if (driverId.trim() === '' && userPrivileges.supplies) {
            toastNotification('Invalid delivery driver selected!', 'error');
            return;
        }

        const orderId = shoppingCart.orderId ? shoppingCart.orderId.trim() : '';
        const httpMethod = orderId.trim() === '' ? 'POST' : 'PATCH';

        const httpRequestConfig = {
            url: 'supply/order',
            method: httpMethod as Method,
            data: { orderId, siteId, driverId, orderItems: cartItems, orderStatus: userPrivileges.supplies ? 2 : 1 },
            headers: {
                'Content-Type': 'application/json',
            },
        };
        sendHttpRequest(
            httpRequestConfig,
            (response) => {
                if ('success' in response) {
                    dispatch(clearCart());
                    toggleCartHandler();

                    const successMessage = userPrivileges.delivery ? 'submitted successfully' : 'ready for collection';
                    toastNotification(`New order for ${selectedSiteName} ${successMessage}!`, 'success');

                    setTimeout(() => {
                        const redirectUrl = userPrivileges.delivery ? '/home' : '/home/orders';
                        navigate(redirectUrl, { replace: true });
                    }, 350);
                }
            },
            event.currentTarget,
        );
    };

    const { authorisedMembers } = useAuthorisedTeamMembers(false);
    const drivers = authorisedMembers.filter((authorisedMember) => authorisedMember.privileges.delivery);
    const driverSelectOptions = drivers.map((driver) => ({
        value: driver.memberId,
        label: `${driver.firstName} ${driver.lastName}`,
    }));

    const { fetchSites, sites } = useSites();
    useEffect(() => {
        fetchSites();
    }, []);
    const siteSelectOptions = sites.map((site) => ({
        value: site.siteId,
        label: site.siteName,
    }));

    return (
        <>
            <header>
                <div className="header-inner">
                    <div className="header-left">
                        {!userPrivileges.supplies && userPrivileges.delivery ? null : (
                            <Hamburger
                                toggled={navigationBarState}
                                toggle={toggleNavigation}
                                label="Open menu"
                                size={28}
                                rounded
                            />
                        )}

                        <Link to="/home">
                            <i className="main-logo" style={{ width: 40, height: 40 }} />
                        </Link>
                    </div>
                    <div className="header-right">
                        {showGlobalAddButton ? (
                            <>
                                <div id="globalAddNewBtn" style={{ width: 120 }}>
                                    <button
                                        type="button"
                                        className="btn btn-success btn-block p-2 pl-3 pr-3"
                                        onClick={handleAddSomethingNew}
                                    >
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <i
                                                className="custom-icon icon"
                                                style={{ width: 20, height: 20, backgroundColor: '#fff' }}
                                                aria-label="Plus Circle"
                                            />
                                            <span className="ml-auto">Add New</span>
                                        </div>
                                    </button>
                                </div>
                                <div className="btn-divider" />
                            </>
                        ) : null}
                        <div>
                            <button type="button" className="profile-dropdown-toggle">
                                <i
                                    className="custom-icon icon"
                                    style={{ width: 20, height: 20 }}
                                    aria-label="Notification off"
                                />
                            </button>
                        </div>
                        <div className="btn-divider" />
                        <div className="cart-toggle">
                            <button
                                type="button"
                                className="profile-dropdown-toggle cart-toggle cart-count"
                                data-qty={cartItemCount.toString()}
                                onClick={toggleCartHandler}
                            >
                                <i
                                    className="custom-icon icon"
                                    style={{ width: 20, height: 20 }}
                                    aria-label="Shopping Cart"
                                />
                            </button>
                        </div>
                        <div className="btn-divider" />
                        <UserDropdownMenu
                            userLogoutHandler={handleUserLogout}
                            authenticatedUser={{ ...authenticatedUser }}
                        />
                    </div>
                </div>
            </header>
            <QuickOrderCart
                cart={shoppingCart}
                cachedItems={similarCachedItems}
                authenticatedUser={authenticatedUser}
                cachedItemsAddedToCart={cachedItemsAdded}
                siteOptions={siteSelectOptions}
                driverOptions={driverSelectOptions}
                openQuickOrderCart={openCart}
                closeQuickOrderCartHandler={toggleCartHandler}
                selectOptionUpdateHandler={handleDriverAndSiteChange}
                removeItemFromCartHandler={handleRemoveItemFromCart}
                clearCartHandler={handleClearCart}
                checkoutHandler={handleCheckout}
                addCachedItemsHandler={handleAddCachedItems}
                removeCachedItemsHandler={handleRemoveCachedItems}
                transferStockData={{
                    transferStock: false,
                    transferStockToAll: false,
                    selectedDeliveryTeam: [],
                    deliveryTeam: [],
                }}
                transferStockDataUpdateHandler={() => {}}
            />
        </>
    );
};

export default AuthenticationPageLayoutHeader;
