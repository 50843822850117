import {useCallback, useState} from 'react';

import useHttp from '../useHttp';
import {TeamMemberListType} from '../../config/types/team/teamMember';

const useTeamMemberList = () => {
    const {sendHttpRequest, httpInProgress: fetchingTeamMembers, httpError: teamMemberHttpError} = useHttp();
    const [teamMemberList, setTeamMemberList] = useState<TeamMemberListType[]>([]);

    const fetchTeamMembers = useCallback((filterParams?: Record<string, any>) => {
        if (fetchingTeamMembers) {
            return;
        }

        const httpRequestConfig = {
            url: '/team/member',
            params: filterParams,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        sendHttpRequest(httpRequestConfig, (updatedTeamMembers) => {
            if ('records' in updatedTeamMembers) {
                setTeamMemberList(updatedTeamMembers.records as TeamMemberListType[]);
            }
        });
    }, []);

    return {fetchTeamMembers, fetchingTeamMembers, teamMemberHttpError, teamMemberList};
};

export default useTeamMemberList;
