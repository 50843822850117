import React from 'react';

import { OptionCard } from '../../../Card';
import { DividerWithCenteredText } from '../../../Divider';

type TransferItemsToTeamWalletType = {
    transferStockData: {
        transferStock: boolean;
        transferStockToAll: boolean;
        selectedDeliveryTeam: string[];
        deliveryTeam: { memberId: string; name: string; tel: string }[];
    };
    transferStockDataUpdateHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const TransferItemsToTeamWallet: React.FC<TransferItemsToTeamWalletType> = ({
    transferStockData,
    transferStockDataUpdateHandler,
}) => (
    <>
        <OptionCard
            title="Transfer Stock to Delivery team"
            description="Allocate stock that the delivery team can use to top up on-site supplies."
            name="transferStock"
            checked={transferStockData.transferStock}
            onChange={transferStockDataUpdateHandler}
        />
        <div className="form-group form-group-default bg-transparent pb-2">
            <h6>Recipients</h6>
            <hr style={{ backgroundColor: 'rgba(6, 18, 35, 0.14)' }} />
            <OptionCard
                title="Transfer Stock to all"
                description="Allocate stock every member of the delivery team."
                name="transferStockToAll"
                checked={transferStockData.transferStockToAll}
                onChange={transferStockDataUpdateHandler}
            />
            <DividerWithCenteredText>OR</DividerWithCenteredText>
            {transferStockData.deliveryTeam.map((member) => (
                <OptionCard
                    key={member.memberId}
                    title={member.name}
                    description={member.tel}
                    name="deliveryTeam"
                    checked={!!transferStockData.selectedDeliveryTeam.find((team) => team === member.memberId)}
                    onChange={transferStockDataUpdateHandler}
                />
            ))}
        </div>
    </>
);

export default TransferItemsToTeamWallet;
