import { CartItemType } from '../../config/types/cart';

export const formatCartItemQuantity = (item: CartItemType) => {
    const { itemQuantity, itemCacheQuantity, itemMinimumSaleQuantity, itemSIUnit } = item;

    const totalItemQuantity = itemQuantity + itemCacheQuantity;
    let updatedCartItemQuantity = totalItemQuantity.toString();

    if (itemMinimumSaleQuantity > 1) {
        updatedCartItemQuantity = `${totalItemQuantity / itemMinimumSaleQuantity} x ${itemMinimumSaleQuantity}`;
    }

    updatedCartItemQuantity += itemSIUnit ? ` ${itemSIUnit}(s)` : '';
    return updatedCartItemQuantity;
};
