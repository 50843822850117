import React from 'react';

interface HeaderSearchType extends React.HTMLProps<HTMLInputElement> {
    clearSearchHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const SearchBar = React.forwardRef<HTMLInputElement, HeaderSearchType>((props, ref) => {
    const { clearSearchHandler, defaultValue, onChange } = props;
    const userSearching = defaultValue && defaultValue.toString().trim().length > 0;

    if (onChange) {
        return (
            <div id="globalSearchBar" className={`default-search ${userSearching ? 'focused' : ''}`}>
                <div>
                    <i className="custom-icon icon" aria-label="Search" />
                </div>
                <input
                    type="search"
                    ref={ref}
                    placeholder="Search..."
                    defaultValue={defaultValue}
                    onChange={onChange}
                />
                <div className="clear-search ">
                    <button
                        type="button"
                        className={`btn btn-link ${userSearching ? '' : 'disabled'}`}
                        onClick={clearSearchHandler}
                    >
                        <i className="custom-icon icon" aria-label="Close" />
                    </button>
                </div>
            </div>
        );
    }

    return null;
});

export default SearchBar;
