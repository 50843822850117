import React from 'react';
import { SingleValue, MultiValue } from 'react-select';

import ModalContainer, { ModalHeader } from '../../../containers/ModalContainer';
import { SelectInput, SelectSingleOptionType } from '../../FormElements';
import Button from '../../Button';

import {
    CartListItem,
    CartModalFooter,
    EmptyCart,
    TeamMemberWalletItemList,
    TransferItemsToTeamWallet,
} from './components';
import { CartType } from '../../../config/types/cart';
import { SupplyCacheInventoryListType } from '../../../config/types/supplies/supplyCacheInventory';
import { AuthenticationType } from '../../../config/types/authentication';

type QuickOrderCartType = {
    cart: CartType;
    cachedItems: SupplyCacheInventoryListType[];
    authenticatedUser: AuthenticationType;
    cachedItemsAddedToCart: boolean;
    siteOptions: SelectSingleOptionType[];
    driverOptions: SelectSingleOptionType[];
    openQuickOrderCart: boolean;
    closeQuickOrderCartHandler: () => void;
    selectOptionUpdateHandler: (optionName: string, optionValue: string) => void;
    removeItemFromCartHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    clearCartHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    checkoutHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    addCachedItemsHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    removeCachedItemsHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    transferStockData: {
        transferStock: boolean;
        transferStockToAll: boolean;
        selectedDeliveryTeam: string[];
        deliveryTeam: { memberId: string; name: string; tel: string }[];
    };
    transferStockDataUpdateHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const QuickOrderCart: React.FC<QuickOrderCartType> = ({
    cart,
    cachedItems,
    authenticatedUser,
    cachedItemsAddedToCart,
    siteOptions,
    driverOptions,
    openQuickOrderCart,
    closeQuickOrderCartHandler,
    selectOptionUpdateHandler,
    removeItemFromCartHandler,
    clearCartHandler,
    checkoutHandler,
    addCachedItemsHandler,
    removeCachedItemsHandler,
    transferStockData,
    transferStockDataUpdateHandler,
}) => {
    const { privileges: userPrivileges } = authenticatedUser;
    const { siteId, driverId, cartItems } = cart;

    const selectedSite = siteOptions.find((siteOption) => siteOption.value === siteId);
    const selectedDriver = driverOptions.find((driverOption) => driverOption.value === driverId);
    const handleSelectOptionUpdate = (
        optionName: string,
        option: SingleValue<SelectSingleOptionType> | MultiValue<SelectSingleOptionType>,
    ) => {
        selectOptionUpdateHandler(optionName, (option as SelectSingleOptionType)?.value || '');
    };

    const cartModalExitHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        closeQuickOrderCartHandler();
    };

    return (
        <ModalContainer show={openQuickOrderCart} closeModal={closeQuickOrderCartHandler}>
            <ModalHeader modalExitHandler={cartModalExitHandler}>
                <h5 className="mr-auto">Cart</h5>
                {cartItems.length > 0 && (
                    <span className="text-right">
                        <Button onClick={clearCartHandler} className="btn-danger">
                            <i className="custom-icon icon" style={{ backgroundColor: '#ffffff' }} aria-label="Trash" />
                        </Button>
                    </span>
                )}
            </ModalHeader>
            <div className="modal-body">
                {cartItems.length > 0 ? (
                    <>
                        {userPrivileges.supplies && transferStockData.transferStock ? (
                            <TransferItemsToTeamWallet
                                transferStockData={transferStockData}
                                transferStockDataUpdateHandler={transferStockDataUpdateHandler}
                            />
                        ) : null}

                        {userPrivileges.supplies && transferStockData.transferStock ? null : (
                            <SelectInput
                                label={`${selectedSite ? 'Change' : 'Select'} site`}
                                defaultValue={selectedSite}
                                options={siteOptions}
                                onChange={(option) => {
                                    handleSelectOptionUpdate('siteId', option as SelectSingleOptionType);
                                }}
                            />
                        )}

                        {userPrivileges.supplies ? (
                            <SelectInput
                                label="Driver"
                                placeholder="Select driver"
                                defaultValue={selectedDriver}
                                options={driverOptions}
                                onChange={(option) => {
                                    handleSelectOptionUpdate('driverId', option as SelectSingleOptionType);
                                }}
                            />
                        ) : null}

                        {userPrivileges.supplies && transferStockData.transferStock && cachedItems.length > 0 ? (
                            <TeamMemberWalletItemList
                                walletItems={cachedItems}
                                walletItemsAddedToCart={cachedItemsAddedToCart}
                                addWalletItemsToCartHandler={addCachedItemsHandler}
                                removeWalletItemsFromCartHandler={removeCachedItemsHandler}
                            />
                        ) : null}
                        {cartItems.map((cartItem) => (
                            <CartListItem
                                key={cartItem.itemId}
                                cartItem={cartItem}
                                removeItemFromCartHandler={removeItemFromCartHandler}
                            />
                        ))}
                        <CartModalFooter
                            transferStock={transferStockData.transferStock}
                            userPrivileges={userPrivileges}
                            cartModalExitHandler={cartModalExitHandler}
                            checkoutHandler={checkoutHandler}
                        />
                    </>
                ) : (
                    <EmptyCart cartModalExitHandler={cartModalExitHandler} />
                )}
            </div>
        </ModalContainer>
    );
};

export default QuickOrderCart;
