import React from 'react';
import {Link} from 'react-router-dom';

type AuthenticationPageLayoutFooterType = {
    termsBtnText?: string;
};

const AuthenticationPageLayoutFooter: React.FC<AuthenticationPageLayoutFooterType> = ({termsBtnText}) => {
    if (termsBtnText) {
        return (
            <div className="auth-wrapper-footer">
                <div className="col-12 text-center">
                <span className="small">
                    By clicking on the {termsBtnText} button, you agree to our{' '}
                    <Link to="/terms" className="text-primary semi-bold">
                        Terms
                    </Link>{' '}
                    and have read and acknowledge our{' '}
                    <Link to="/privacy" className="text-primary semi-bold">
                        Privacy
                    </Link>{' '}
                    Statement.
                </span>
                </div>
            </div>
        );
    }

    return null;
};

AuthenticationPageLayoutFooter.defaultProps = {
    termsBtnText: undefined,
};

export default AuthenticationPageLayoutFooter;
