/**
 Capitalise first letter of string or each word in a sentence
 @param {string} str - sentence or word to be separated
 @param {string} strSeparator - sentence or word separator (default = ' ' [empty string])
 @return string
 */
export function capitalizeFirstLetter(str: string, strSeparator = ' ') {
    const sentenceParts = str.split(strSeparator);
    if (sentenceParts.length > 0) {
        const newSentence = sentenceParts.map(
            (phrase) => phrase.charAt(0).toUpperCase() + phrase.slice(1).toLowerCase(),
        );
        return newSentence.join(strSeparator);
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 Format number to n decimal places
 @param {number} num - number to be formatted
 @param {number} decimalPlaces - number of decimal places (default = 2)
 @return string
 */
export function formatNumber(num: number, decimalPlaces = 2) {
    if (Number.isNaN(num) || num === 0) {
        return '0.00';
    }

    return typeof num.toFixed === 'function' ? num.toFixed(decimalPlaces) : `${num}`;
}

/**
 Format number to currency format
 @param {number} price - number to be formatted
 @param {string} currencySymbol - number of decimal places (default = 2)
 @return string
 */
export function formatPrice(price: number, currencySymbol = 'R') {
    const strPrice = formatNumber(price, 2).toString();
    const a = strPrice.split('');

    if (price > 1000000000) a.splice(a.length - 12, 0, ',');

    if (price > 1000000) a.splice(a.length - 9, 0, ',');

    if (price > 1000) a.splice(a.length - 6, 0, ',');

    if (price < 0) {
        return `(${currencySymbol}${a.slice(1, a.length).join('')})`;
    }

    return currencySymbol + a.join('');
}

const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

/**
 Abbreviate number to n decimal places
 @param {number} number - number to be formatted
 @param {number} decimalPlaces - number of decimal places (default = 2)
 @return string
 */
export function abbreviateNumber(number: number, decimalPlaces = 2) {
    // what tier? (determines SI symbol)
    const tier = Math.log10(Math.abs(number)) / 3 || 0;

    // if zero, we don't need a suffix
    if (tier === 0) return parseFloat(number.toString()).toFixed(decimalPlaces);

    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = 10 ** (tier * 3);

    // scale the number
    const scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(decimalPlaces) + suffix;
}

export const abbrNum = (number: number, decPlaces = 2) => {
    // 2 decimal places => 100, 3 => 1000, etc
    // eslint-disable-next-line no-param-reassign
    decPlaces = 10 ** decPlaces;

    // Enumerate number abbreviations
    const abbrev = ['k', 'm', 'b', 't'];

    // Go through the array backwards, so we do the largest first
    // eslint-disable-next-line no-plusplus
    for (let i = abbrev.length - 1; i >= 0; i--) {
        // Convert array index to "1000", "1000000", etc
        const size = 10 ** (i + 1) * 3;

        // If the number is bigger or equal do the abbreviation
        if (size <= number) {
            // Here, we multiply by decPlaces, round, and then divide by decPlaces.
            // This gives us nice rounding to a particular decimal place.
            // eslint-disable-next-line no-param-reassign
            number = Math.round((number * decPlaces) / size) / decPlaces;

            // Handle special case where we round up to the next abbreviation
            if (number === 1000 && i < abbrev.length - 1) {
                // eslint-disable-next-line no-param-reassign
                number = 1;
                // eslint-disable-next-line no-plusplus
                i++;
            }

            // Add the letter for the abbreviation
            // eslint-disable-next-line no-param-reassign
            return number.toString() + abbrev[i];
        }
    }

    return number.toString();
};
