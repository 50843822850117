import React from 'react';

type FullPageProgressIndicatorTypes = React.HTMLProps<HTMLDivElement>;

const FullPageProgressIndicator: React.FC<FullPageProgressIndicatorTypes> = (props) => {
    const { style } = props;

    return (
        <div className="page-transition" style={{ ...style }}>
            <div className="loading">
                <span />
                <span />
                <span />
                <span />
            </div>
        </div>
    );
};

export default FullPageProgressIndicator;
