import { AuthenticationType } from '../../types/authentication';

export const authenticatedUserDataTemplate: AuthenticationType = {
    authenticationToken: '',
    accountNo: '',
    memberId: '',
    firstName: '',
    lastName: '',
    privileges: {
        accountAdmin: false,
        delivery: false,
        supplies: false,
        team: false,
        siteInspection: false,
        sites: false,
        orders: false,
        shop: false,
        vehicles: false,
        settings: false,
        deleteRecord: false,
    },
};
