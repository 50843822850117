import { useState, useCallback } from 'react';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

import { useToastNotification } from './notification';
import { showHttpProcessIndicator } from '../components/Button';
import { showInputElementError } from '../utilities/inputValidation/inputValidationHandlers';
import { useAppSelector } from './redux';

const useHttp = () => {
    const [httpInProgress, setHttpInProgress] = useState(false);
    const [httpError, setHttpError] = useState(false);
    const toastNotification = useToastNotification();
    const userDetail = useAppSelector((state) => state.authenticatedUser);

    const sendHttpRequest = useCallback(
        (
            requestConfig: AxiosRequestConfig,
            requestCompletionHandler: (response: AxiosResponse<any, any>) => void,
            actionButton?: HTMLButtonElement,
        ) => {
            if (actionButton) {
                showHttpProcessIndicator(actionButton);
            }
            const { authenticationToken } = userDetail;
            /* eslint-disable no-param-reassign */
            if (authenticationToken && authenticationToken?.trim() !== '') {
                if (requestConfig.headers) {
                    requestConfig.headers = {
                        ...requestConfig.headers,
                        Authorization: `Bearer ${authenticationToken.trim()}`,
                    };
                } else {
                    requestConfig.headers = {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationToken.trim()}`,
                    };
                }
            }
            /* eslint-disable no-param-reassign */

            axios({ ...requestConfig, baseURL: 'https://hsd.kreativware.co.za' })
                .then((response) => {
                    requestCompletionHandler(response.data);
                    setHttpInProgress(false);
                    if (actionButton) {
                        showHttpProcessIndicator(actionButton);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        const { error: httpErrorMessage, fields: validationErrors } = error.response.data;
                        if (validationErrors && Object.entries(validationErrors).length > 0) {
                            showInputElementError(validationErrors);
                        } else {
                            toastNotification((httpErrorMessage as string) || error.message, 'error');
                        }
                    }
                    if (actionButton) {
                        showHttpProcessIndicator(actionButton);
                    }
                    setHttpError(true);
                    setHttpInProgress(false);
                });
        },
        [],
    );

    return { sendHttpRequest, httpInProgress, httpError };
};

export default useHttp;
